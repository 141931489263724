@import "src/variables";

.login-page-main {
  background-image: linear-gradient(23deg, rgba(202, 202, 202, 0.02) 0px, rgba(202, 202, 202, 0.02) 13%, transparent 13%, transparent 80%, rgba(11, 11, 11, 0.02) 80%, rgba(11, 11, 11, 0.02) 100%),
  linear-gradient(42deg, rgba(98, 98, 98, 0.02) 0px, rgba(98, 98, 98, 0.02) 36%, transparent 36%, transparent 77%, rgba(252, 252, 252, 0.02) 77%, rgba(252, 252, 252, 0.02) 100%),
  linear-gradient(293deg, rgba(173, 173, 173, 0.02) 0px, rgba(173, 173, 173, 0.02) 2%, transparent 2%, transparent 12%, rgba(59, 59, 59, 0.02) 12%, rgba(59, 59, 59, 0.02) 100%),
  linear-gradient(160deg, rgba(87, 87, 87, 0.02) 0px, rgba(87, 87, 87, 0.02) 18%, transparent 18%, transparent 55%, rgba(247, 247, 247, 0.02) 55%, rgba(247, 247, 247, 0.02) 100%);
  min-height: 100vh;
  overflow: auto;
}

.login-page-content {
  display: flex;
  min-height: calc(100vh - 60px);
  align-items: center;
}