@import "variables";

html {
  scroll-behavior: smooth;
}

// NOTE: To allow the content to fill the empty area.
html, body, .root, .App {
  height: 100%;
}

*:focus {
  outline: none !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.primary-background-color {
  background-color: $primary-color;
}

.primary-color {
  color: $primary-color;
}

.secondary-background-color {
  background-color: $secondary-color;
}

.secondary-color {
  color: $secondary-color;
}

.home-ads-section {
  //background-image: url("/home-ads-section-backgraound-1.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.left-align-slick > .slick-list > .slick-track {
  margin-left: 0;
  margin-right: 0;
}

.shimmer {
  background: #f6f7f8 linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%) no-repeat;
  background-size: 1000px 100%;
  display: inline-block;
  position: relative;

  -webkit-animation-duration: .7s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: shimmering-animation;
  -webkit-animation-timing-function: linear;
}

@keyframes shimmering-animation {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

@-webkit-keyframes shimmering-animation {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.menu-section-bg {
  background-color: rgba(240, 248, 255, 0.024);
  background-image: linear-gradient(
                  23deg, rgba(202, 202, 202, 0.02) 0px, rgba(202, 202, 202, 0.04) 13%, transparent 13%, transparent 80%, rgba(11, 11, 11, 0.02) 80%, rgba(11, 11, 11, 0.02) 100%), linear-gradient(
                  42deg, rgba(98, 98, 98, 0.02) 0px, rgba(98, 98, 98, 0.05) 36%, transparent 36%, transparent 77%, rgba(252, 252, 252, 0.02) 77%, rgba(252, 252, 252, 0.02) 100%), linear-gradient(
                  293deg, rgba(173, 173, 173, 0.1) 0px, rgba(173, 173, 173, 0.2) 2%, transparent 2%, transparent 12%, rgba(59, 59, 59, 0.02) 12%, rgba(59, 59, 59, 0.02) 100%), linear-gradient(
                  160deg, rgba(87, 87, 87, 0.02) 0px, rgba(87, 87, 87, 0.02) 18%, transparent 18%, transparent 55%, rgba(247, 247, 247, 0.02) 55%, rgba(247, 247, 247, 0.12) 100%);
}