.FileUploadControl_SelectArea-root {
  border: 1px dashed rgba(0, 0, 0, 0.12);
  display: flex;
  outline: none;
  padding: 48px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.FileUploadControl_SelectArea-root:hover {
  cursor: pointer;
  opacity: 0.5;
  background-color: rgba(0, 0, 0, 0.04);
}

.FileUploadControl_SelectArea-root input[type="file"] {
  display: none;
}

.FileUploadControl_SelectArea-root .icon {
  width: 150px;
  height: auto;
}